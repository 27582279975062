// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "r_q5 d_gv d_cs";
export var heroHeaderCenter = "r_gw d_gw d_cs d_dw";
export var heroHeaderRight = "r_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "r_q6 d_gr d_cw";
export var heroParagraphCenter = "r_gs d_gs d_cw d_dw";
export var heroParagraphRight = "r_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "r_q7 d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "r_q8 d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "r_q9 d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "r_rb d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "r_rc d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "r_rd t_rd";
export var heroExceptionNormal = "r_rf t_rf";
export var heroExceptionLarge = "r_rg t_rg";
export var Title1Small = "r_rh t_rh t_rq t_rr";
export var Title1Normal = "r_rj t_rj t_rq t_rs";
export var Title1Large = "r_rk t_rk t_rq t_rt";
export var BodySmall = "r_rl t_rl t_rq t_rK";
export var BodyNormal = "r_rm t_rm t_rq t_rL";
export var BodyLarge = "r_rn t_rn t_rq t_rM";